"use strict";
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
Object.defineProperty(exports, "__esModule", { value: true });
exports.numeral = void 0;
function numeral(value, options) {
    var _a, _b, _c, _d;
    return new Intl.NumberFormat('de-DE', {
        style: (_a = options === null || options === void 0 ? void 0 : options.style) !== null && _a !== void 0 ? _a : 'currency',
        currency: (_b = options === null || options === void 0 ? void 0 : options.currency) !== null && _b !== void 0 ? _b : 'EUR',
        minimumFractionDigits: (_c = options === null || options === void 0 ? void 0 : options.fractionDigits) !== null && _c !== void 0 ? _c : 2,
        maximumFractionDigits: (_d = options === null || options === void 0 ? void 0 : options.fractionDigits) !== null && _d !== void 0 ? _d : 2,
    }).format(Number(value));
}
exports.numeral = numeral;
