"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.papoI18nMessages = void 0;
exports.papoI18nMessages = {
    de: {
        Patientendaten: 'Patientendaten',
        Datenschutz: 'Datenschutz',
        'Ihre Praxis hat Ihnen Dokumente freigegeben.': 'Ihre Praxis hat Ihnen Dokumente freigegeben.',
        'Bitte geben Sie Ihren Vornamen und Geburtsdatum zum Abgleich Ihrer Daten an.': 'Bitte geben Sie Ihren Vornamen und Geburtsdatum zum Abgleich Ihrer Daten an.',
        'Unter diesem Link ist nichts freigegeben. Entweder ist der Link falsch oder abgelaufen.': 'Unter diesem Link ist nichts freigegeben. Entweder ist der Link falsch oder abgelaufen.',
        Geburtsdatum: 'Geburtsdatum',
        Tag: 'Tag',
        Monat: 'Monat',
        Jahr: 'Jahr',
        Login: 'Login',
        'Patienten-Portal': 'Patienten-Portal',
        'Für den Fall, dass es sich um ein Kind handelt, bitten wir um Angabe des Vornamens und des Geburtsdatums des Kindes.': 'Für den Fall, dass es sich um ein Kind handelt, bitten wir um Angabe des Vornamens und des Geburtsdatums des Kindes.',
        'Es wurde zu oft versucht sich mit falschen Daten anzumelden. Der Zugang zu Ihren Dokumenten ist nun zu Ihrer eigenen Sicherheit gesperrt. Bitte wenden Sie sich an Ihre Praxis.': 'Es wurde zu oft versucht sich mit falschen Daten anzumelden. Der Zugang zu Ihren Dokumenten ist nun zu Ihrer eigenen Sicherheit gesperrt. Bitte wenden Sie sich an Ihre Praxis.',
        Fragebögen: 'Fragebögen',
        Fragebogen: 'Fragebogen',
        starten: 'starten',
        fertig: 'fertig',
        'Dokumente zum Unterschreiben': 'Dokumente zum Unterschreiben',
        Dokument: 'Dokument',
        unterschreiben: 'unterschreiben',
        'bereits unterschrieben.': 'bereits unterschrieben.',
        'Unterlagen zum Download': 'Unterlagen zum Download',
        Abmelden: 'Abmelden',
        'läuft ab': 'läuft ab',
        'Aktion abbrechen? Ihre Eingaben werden nicht gespeichert.': 'Aktion abbrechen? Ihre Eingaben werden nicht gespeichert.',
        'Fragebogen erfolgreich gesendet!': 'Fragebogen erfolgreich gesendet!',
        'Fehler beim Speichern:': 'Fehler beim Speichern:',
        'Ihre Eingaben wurden gespeichert.': 'Ihre Eingaben wurden gespeichert.',
        'Sie können diesen Tab nun schließen.': 'Sie können diesen Tab nun schließen.',
        'Aufgabe abgebrochen.': 'Aufgabe abgebrochen.',
        Einwilligungserklärung: 'Einwilligungserklärung',
        'Dokument unterschreiben': 'Dokument unterschreiben',
    },
    en: {
        Patientendaten: 'Patient Data',
        Datenschutz: 'Data Protection',
        'Ihre Praxis hat Ihnen Dokumente freigegeben.': 'Your practice has released documents to you.',
        'Bitte geben Sie Ihren Vornamen und Geburtsdatum zum Abgleich Ihrer Daten an.': 'Please enter your first name and date of birth to match your data.',
        'Unter diesem Link ist nichts freigegeben. Entweder ist der Link falsch oder abgelaufen.': 'Nothing has been shared under this link. Either the link is wrong or it has expired.',
        Geburtsdatum: 'Date of Birth',
        Tag: 'Day',
        Monat: 'Month',
        Jahr: 'Year',
        Login: 'Login',
        'Patienten-Portal': 'Patient Portal',
        'Für den Fall, dass es sich um ein Kind handelt, bitten wir um Angabe des Vornamens und des Geburtsdatums des Kindes.': 'In the event that it is a child, please provide the first name and date of birth of the child.',
        'Es wurde zu oft versucht sich mit falschen Daten anzumelden. Der Zugang zu Ihren Dokumenten ist nun zu Ihrer eigenen Sicherheit gesperrt. Bitte wenden Sie sich an Ihre Praxis.': 'There have been too many attempts to log in with incorrect data. Access to your documents is now blocked for your own safety. Please contact your practice.',
        Fragebogen: 'Questionnaire',
        Fragebögen: 'Questionnaires',
        starten: 'start',
        fertig: 'finished',
        'Dokumente zum Unterschreiben': 'Documents to sign',
        Dokument: 'Document',
        unterschreiben: 'sign',
        'bereits unterschrieben.': 'already signed.',
        'Unterlagen zum Download': 'Documents for download',
        Abmelden: 'Logout',
        'läuft ab': 'expires',
        'Aktion abbrechen? Ihre Eingaben werden nicht gespeichert.': 'Cancel action? Your entries will not be saved.',
        'Fragebogen erfolgreich gesendet!': 'Questionnaire sent successfully!',
        'Fehler beim Speichern:': 'Error while saving:',
        'Ihre Eingaben wurden gespeichert.': 'Your entries have been saved.',
        'Sie können diesen Tab nun schließen.': 'You can now close this tab.',
        'Aufgabe abgebrochen.': 'Task aborted.',
        Einwilligungserklärung: 'Consent form',
        'Dokument unterschreiben': 'Sign document',
    },
};
