"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSSFApi = void 0;
const config_1 = require("../../config");
function createSSFApi(api) {
    return {
        getShareInfos: (shareId) => api.r4cDispatch.get(`ssf/shareInfos/${shareId}`),
        validatePassword: (shareId, password) => api.r4cDispatch.post(`ssf/validatePassword`, {
            shareId,
            password,
        }),
        getDownloadLink: (fileId, password) => `${(0, config_1.getR4cDispatcherUrl)()}/ssf/download/${fileId}/${password}`,
        // admin routes
        adminGetSharedDocumentsInfos: () => api.r4cDispatch.get(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminGetSharedDocumentsInfos`),
        adminGetSharedTasks: () => api.r4cDispatch.get(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminGetSharedTasksInfos`),
        checkMailSendIds: (messageIds) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/checkMailSendIds`, {
            messageIds,
        }),
        adminResentShareEmailDocument: (shareId, overrideMail) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminResentShareEmailDocument`, {
            shareId,
            overrideMail,
        }),
        adminResentShareEmailTask: (shareId, overrideMail) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminResentShareEmailTask`, { shareId, overrideMail }),
        adminResetShareIdDocument: (shareId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminResetShareIdDocument`, { shareId }),
        adminResetShareIdTask: (shareId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminResetShareIdTask`, { shareId }),
        adminDeleteSharedFile: (fileId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminDeleteSharedFile`, { fileId }),
        adminDeleteSharedTask: (taskId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminDeleteSharedTask`, { taskId }),
        adminGetSharedFile: (fileId) => api.r4cDispatch.post(`${(0, config_1.getR4cDispatcherUrl)()}/ssf/adminGetSharedFile`, { fileId }),
    };
}
exports.createSSFApi = createSSFApi;
