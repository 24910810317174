"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigRaw = exports.benchmarkPatientSeitIntervalle = void 0;
const types_1 = require("../../../types");
const benchmark_config_hkp_1 = require("./benchmark.config.hkp");
const benchmark_config_leistungen_1 = require("./benchmark.config.leistungen");
const benchmark_config_papzr_1 = require("./benchmark.config.papzr");
const benchmark_config_patienten_1 = require("./benchmark.config.patienten");
const benchmark_config_termine_1 = require("./benchmark.config.termine");
const benchmark_config_tz_1 = require("./benchmark.config.tz");
const benchmark_config_umsatz_1 = require("./benchmark.config.umsatz");
const benchmark_config_rezepteHmvAuKt_1 = require("./benchmark.config.rezepteHmvAuKt");
const benchmark_config_behandlungen_1 = require("./benchmark.config.behandlungen");
const benchmark_config_tbv2_1 = require("./benchmark.config.tbv2");
const benchmark_config_stichworte_1 = require("./benchmark.config.stichworte");
let endi = 0;
exports.benchmarkPatientSeitIntervalle = [
    { name: '< 1 Monat', key: '1monat', von: 0, bis: (endi += 30) },
    { name: '1 - 6 Monate', key: '6monate', von: endi, bis: (endi += 5 * 30.5) },
    { name: '6 - 12 Monate', key: '1jahr', von: endi, bis: (endi += 6 * 30.5) },
    { name: '1 - 2 Jahre', key: '2jahre', von: endi, bis: (endi += 12 * 30.5) },
    { name: '2 - 5 Jahre', key: '5jahre', von: endi, bis: (endi += 3 * 12 * 30.5) },
    { name: '5 - 10 Jahre', key: '10jahre', von: endi, bis: (endi += 5 * 12 * 30.5) },
    { name: '> 10 Jahre', key: 'mehrAls10jahre', von: endi, bis: (endi += 50 * 12 * 30.5) }, // >10 Jahre
];
function benchmarkConfigRaw(settings, topStichworte) {
    return {
        name: 'KPI',
        isHeader: true,
        isRootNode: true,
        childs: {
            [types_1.BenchmarkParts.PATIENTEN]: {
                name: 'Patienten',
                isHeader: true,
                isRootNode: true,
                visible: false,
                collapsed: false,
                isTab: true,
                childs: {
                    behandeltePatienten: (0, benchmark_config_patienten_1.benchmarkConfigPatienten)(),
                    papzr: (0, benchmark_config_papzr_1.benchmarkConfigPaPzr)(),
                    rezepteHmvAuKt: (0, benchmark_config_rezepteHmvAuKt_1.benchmarkConfigRezepteHmvAuKt)(),
                    stichworte: (0, benchmark_config_stichworte_1.benchmarkConfigStichworte)(topStichworte),
                },
            },
            [types_1.BenchmarkParts.TERMINE]: {
                name: 'Termine',
                isHeader: true,
                isRootNode: true,
                visible: false,
                collapsed: false,
                isTab: true,
                childs: {
                    _termine: (0, benchmark_config_termine_1.benchmarkConfigTermine)(),
                    terminbuchv2: (0, benchmark_config_tbv2_1.benchmarkConfigTBv2)(),
                    terminzeiten: (0, benchmark_config_tz_1.benchmarkConfigTerminzeiten)(),
                },
            },
            [types_1.BenchmarkParts.UMSATZ]: {
                name: 'Umsatz',
                isHeader: true,
                isRootNode: true,
                visible: false,
                collapsed: false,
                isTab: true,
                childs: {
                    _umsatz: (0, benchmark_config_umsatz_1.benchmarkConfigUmsatz)(),
                    leistungen: (0, benchmark_config_leistungen_1.benchmarkConfigLeistungen)(),
                },
            },
            [types_1.BenchmarkParts.HKPS]: { ...(0, benchmark_config_hkp_1.benchmarkConfigHKP)(), isTab: true },
            [types_1.BenchmarkParts.BEHANDLUNGEN]: { ...(0, benchmark_config_behandlungen_1.benchmarkConfigBehandlungen)(settings.leistungen.implantat), isTab: true },
        },
    };
}
exports.benchmarkConfigRaw = benchmarkConfigRaw;
